/**
 * 出欠一覧画面用のJS
 */
(function () {
    this.ManageList = (function () {
        function ManageList() {
            this.init();
            this.user_email = document.querySelector('[data-role="user_email"]').innerHTML;
            this.zip_password = document.querySelector('[data-role="zip_password"]').innerHTML;
            this.group_code = document.querySelector('[data-role="group_code"]').innerHTML;

            var _this = this;
            this.setListener();
            this.toggleCsvDownload();

            // commonの読み込みを待ってから処理開始
            var timerId = setInterval(function () {
                if (wdCommonLoaded) {
                    clearInterval(timerId);
                    _this.oneWCallback();
                }
            }, 100);
        }

        ManageList.prototype.init = function () {
            /**
             * Vueオブジェクト
             * @type {Vue}
             */
            var app = new Vue({
                el: '[data-view="manage_list"]',
                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        designIsOld: false,
                        entries: [], // 出欠一覧
                        entryByIds: {}, // 出欠一覧。IDをキーにしたオブジェクト
                        detailOpenEntryIds: {},
                        entryModalTemplate: null,
                        errors: [],
                    }
                },
                /**
                 * インスタンス生成時に実行される処理
                 */
                created: function () {
                    this.designIsOld = document.querySelector('[data-role="design_is_old"]').innerHTML === '1';
                    this.entries = JSON.parse(document.querySelector('[data-role="entries_json"]').innerHTML);
                    this.entryModalTemplate = Handlebars.compile($('#entry_modal_template').html());

                    // 出欠一覧をIDをキーに整形
                    var entryByIds = {};
                    var detailOpenEntryIds = {};
                    this.entries.forEach(function (entry) {
                        entryByIds[entry.entry_id] = entry;
                        detailOpenEntryIds[entry.entry_id] = false; // 初期は閉じた状態
                    });
                    this.entryByIds = entryByIds;
                    this.detailOpenEntryIds = detailOpenEntryIds;
                },
                /**
                 * 各種メソッド
                 */
                methods: {
                    /**
                     * Y-m-d H:i:s 形式の日時を H:i 形式の時間に変換
                     * @param {*} datetime
                     * @returns
                     */
                    createTime: function (datetime) {
                        var dateObj = new Date(datetime);
                        var hours = String(dateObj.getHours()).padStart(2, '0');
                        var minutes = String(dateObj.getMinutes()).padStart(2, '0');
                        return `${hours}:${minutes}`;
                    },
                    /**
                     * Y-m-d H:i:s 形式の日時を n月j日 形式の日付に変換
                     * @param {*} datetime
                     * @returns
                     */
                    createDate: function (datetime) {
                        var dateObj = new Date(datetime);
                        var month = dateObj.getMonth() + 1; // getMonth()は0始まりなので1を足す
                        var day = dateObj.getDate();
                        return `${month}月${day}日`;
                    },
                    /**
                     * Y-m-d 形式の日時を Y年n月j日 形式の日付に変換
                     * @param {*} datetime
                     * @returns
                     */
                    formatDate: function (datetime) {
                        var dateObj = new Date(datetime);
                        var year = dateObj.getFullYear();
                        var month = dateObj.getMonth() + 1; // getMonth()は0始まりなので1を足す
                        var day = dateObj.getDate();
                        return `${year}年${month}月${day}日`;
                    },
                    /**
                     * 数値を3桁ごとにカンマ区切りにする
                     * @param {*} number
                     * @returns
                     */
                    numberFormat: function (number) {
                        return String(number).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
                    },
                    /**
                     * 支払い状況のラベルを返す
                     * @param {*} entry
                     * @returns
                     */
                    payStatusLabel: function (entry) {
                        switch (entry.pay_status) {
                            case 'done':
                                return '支払い済み';
                            case 'progress':
                                return '支払い待ち';
                            case 'none':
                                if (entry.entry === 'ご出席' || entry.entry === 'オンライン出席') {
                                    return '当日払い';
                                } else {
                                    return '-';
                                }
                            default:
                                return '-';
                        }
                    },
                    /**
                     * 支払い方法のラベルを返す
                     * @param {*} payMethod
                     * @returns
                     */
                    payMethodLabel: function (payMethod) {
                        switch (payMethod) {
                            case 'credit':
                                return 'クレジットカード';
                            case 'bank':
                                return '銀行振込';
                            case 'tsutsumuto':
                                return 'つつむと';
                            default:
                                return '';
                        }
                    },
                    /**
                     * 新郎新婦区分のラベルを返す
                     * @param {*} guestType
                     * @returns
                     */
                    guestTypeLabel: function (guestType) {
                        var guestTypeLabel = '';
                        if (!!guestType) {
                            guestType = Number(guestType);
                            if (guestType === 1) {
                                guestTypeLabel = '新郎ゲスト';
                            } else if (guestType === 2) {
                                guestTypeLabel = '新婦ゲスト';
                            }
                        }
                        return guestTypeLabel;
                    },
                    /**
                     * htmlEscapeを行う
                     * @param {*} text
                     * @returns
                     */
                    htmlEscape: function (text) {
                        if (!text) {
                            return '';
                        }
                        var htmlEscapes = {
                            '&': '&amp',
                            '<': '&lt',
                            '>': '&gt',
                            '"': '&quot',
                            "'": '&#39'
                        };
                        return text.replace(/[&<>"']/g, (chr) => htmlEscapes[chr]);
                    },
                    /**
                     * 詳細の表示非表示を切り替える
                     * @param {*} entryId
                     */
                    toggleEntry: function (entryId) {
                        Vue.set(this.detailOpenEntryIds, entryId, !this.detailOpenEntryIds[entryId]);
                    },
                    /**
                     * ゲストを新規追加するモーダルを開く
                     */
                    addEntry: function () {
                        var context = {
                            action: 'create',
                            entry_attend_check: 'checked="checked"',
                            guest_type_groom_check: 'checked="checked"',
                            sex_male_check: 'checked="checked"'
                        };
                        var html = this.entryModalTemplate(context);
                        $('[data-remodal-id="entry_modal"]').html(html);
                        $('[data-remodal-id="entry_modal"]').remodal({hashTracking: false}).open();
                    },
                    /**
                     * ゲストを編集するモーダルを開く
                     * @param {*} entryId
                     */
                    editEntry: function (entryId) {
                        var entry = this.entryByIds[entryId];
                        entry.action = 'update';
                        entry.entry_attend_check = entry.entry === 'ご出席' ? 'checked="checked"' : '';
                        entry.entry_absent_check = entry.entry === 'ご欠席' ? 'checked="checked"' : '';
                        entry.entry_online_check = entry.entry === 'オンライン出席' ? 'checked="checked"' : '';
                        entry.sex_male_check = entry.sex === '男性' ? 'checked="checked"' : '';
                        entry.sex_female_check = entry.sex === '女性' ? 'checked="checked"' : '';
                        entry.guest_type_groom_check = entry.guest_type === '1' ? 'checked="checked"' : '';
                        entry.guest_type_bride_check = entry.guest_type === '2' ? 'checked="checked"' : '';

                        var html = this.entryModalTemplate(entry);
                        $('[data-remodal-id="entry_modal"]').html(html);
                        $('[data-remodal-id="entry_modal"]').remodal({hashTracking: false}).open();
                    },
                    /**
                     * ゲストを削除する
                     * @param {*} entryId
                     */
                    deleteEntry: function (entryId) {
                        var entry = this.entryByIds[entryId];
                        if (!!entry.ticket_payment) {
                            if (entry.ticket_payment.status === 'done') {
                                vex.dialog.alert({
                                    message: 'この回答はご祝儀・会費を事前支払済みのため、削除できません。',
                                });
                                return false;
                            }
                            if (entry.ticket_payment.status !== 'expired') {
                                vex.dialog.alert({
                                    message: 'この回答は支払い待ちのため、削除できません。',
                                });
                                return false;
                            }
                        }

                        var $form = $('#entry_delete_form');
                        vex.dialog.confirm({
                            message: 'この回答を削除します。\n削除した回答を元に戻すことはできません。\n本当によろしいですか？',
                            callback: function (value) {
                                if (value === true) {
                                    $form.find('input[name="entry_id"]').val(entryId);
                                    $form.submit();
                                }
                            }
                        });
                        return false;
                    },
                    /**
                     * 決済取り消し処理
                     */
                    cancelPayment: function (entryId) {
                        var $form = $('#cancel_payment_form');
                        $form.find('input[name="entry_id"]').val(entryId);
                        $form.submit();
                    },
                    /**
                     * 会費を1行で表示する
                     * @param {*} formattedTickets
                     * @returns
                     */
                    oneLineTickets: function (formattedTickets) {
                        var ticketTitles = [];
                        var keys = Object.keys(formattedTickets);
                        for (var i = 0; i < keys.length; i++) {
                            var formattedTicket = formattedTickets[keys[i]];
                            ticketTitles.push(formattedTicket.ticket_title + ' ' + formattedTicket.price + '円 × ' + formattedTicket.count);
                        }
                        return ticketTitles.join('、');
                    }
                }
            });
        };

        ManageList.prototype.setListener = function () {

            /**
             * ソート条件変更時の処理
             */
            $('[data-action="sort"]').change(function () {
                $.blockUI();
                location.href = '?sort=' + $(this).val();
            });

            /**
             * ダウンロード方法のトグル処理
             */
            $(document).on('change', '[type="radio"][name="get_type"]', (function (_this) {
                return function (e) {
                    _this.toggleCsvDownload();
                };
            })(this));

            $('[data-action="show_download_dialog"]').click((function (_this) {
                return function (e) {
                    var message = '取得する方法を選択してください。';
                    var attend = Number(document.querySelector('[data-role="count_attend"]').innerHTML);
                    var absent = Number(document.querySelector('[data-role="count_absent"]').innerHTML);

                    if (attend === 0 && absent === 0) {
                        swalToast('出欠の回答がありません。', {icon: 'warning'});
                        return false;
                    }
                    if (_this.group_code === 'novic') {
                        var input_array = [
                                '<label><div style="margin-top: 10px;"><b>ファイル形式</b></div></label>',
                                '<label><div><input type="radio" name="file_type" value="1" checked="checked"><span>CSV</span></div></label>',
                                '<label><div><input type="radio" name="file_type" value="2"><span>Excel</span></div></label>',
                                '<label><div style="margin-top: 10px;"><b>取得方法の選択</b></div></label>',
                                '<label><div><input type="radio" name="get_type" value="1" checked="checked"><span>ダウンロード（全項目）</span></div></label>',
                                '<label><div><input type="radio" name="get_type" value="3"><span>ダウンロード（受付用）</span></div></label>',
                                '<label><div><input type="radio" name="get_type" value="2"><span>PCへメール送信（全項目）</span></div></label>',
                                '<label><div><input type="radio" name="get_type" value="4"><span>PCへメール送信（受付用）</span></div></label>',
                                '<div data-show-if-send-mail="true" style="margin-left: 8px;">',
                                '<div style="margin:5px 0">送信先メールアドレス:<br>',
                                '<input type="email" name="email_address" value="'+_this.user_email+'"></div>',
                                '<div style="margin:5px 0">ZIPパスワード:<br>',
                                '<strong>'+_this.zip_password+'</strong></div>',
                                '※パスワード付きZIPファイルをメール送信します。パスワードは忘れないように管理してください。',
                                '<div data-show-if-has-modal-error="true" class="error_message errors"></div>',
                                '</div>'
                        ];
                    } else {
                        var input_array = [
                                '<label><div style="margin-top: 10px;"><b>ファイル形式</b></div></label>',
                                '<label><div><input type="radio" name="file_type" value="1" checked="checked"><span>CSV</span></div></label>',
                                '<label><div><input type="radio" name="file_type" value="2"><span>Excel</span></div></label>',
                                '<label><div style="margin-top: 10px;"><b>取得方法の選択</b></div></label>',
                                '<label><div><input type="radio" name="get_type" value="1" checked="checked"><span>ダウンロード</span></div></label>',
                                '<label><div><input type="radio" name="get_type" value="2"><span>PCへメール送信</span></div></label>',
                                '<div data-show-if-send-mail="true" style="margin-left: 8px;">',
                                '<div style="margin:5px 0">送信先メールアドレス:<br>',
                                '<input type="email" name="email_address" value="'+_this.user_email+'"></div>',
                                '<div style="margin:5px 0">ZIPパスワード:<br>',
                                '<strong>'+_this.zip_password+'</strong></div>',
                                '※パスワード付きZIPファイルをメール送信します。パスワードは忘れないように管理してください。',
                                '<div data-show-if-has-modal-error="true" class="error_message errors"></div>',
                                '</div>'
                        ];
                    }
                    vex.closeAll(); // 連打されたとき用に表示中のダイアログを閉じる
                    vex.dialog.open({
                        unsafeMessage: message,
                        input: input_array.join(''),
                        beforeClose: function() {
                            // キャンセルの場合は何もせずクローズ
                            if (!this.value) {
                                return true;
                            }

                            // 以下OKの場合
                            if (this.value.get_type === '2' || this.value.get_type === '4') {
                                // メール送信
                                // バリデーション
                                $('[data-show-if-has-modal-error="true"]').empty();
                                $('[data-show-if-has-modal-error="true"]').hide();
                                if (!this.value.email_address) {
                                    $('[data-show-if-has-modal-error="true"]').text('メールアドレスを入力してください');
                                    $('[data-show-if-has-modal-error="true"]').show();
                                    return false;
                                }
                            }

                            if (this.value.get_type === '1' || this.value.get_type === '3') {
                                // ダウンロード処理
                                $file_type = this.value.file_type === '1' ? 'csv' : 'excel';
                                if (this.value.get_type === '1') {
                                    var $element = $('<a download/>').attr({href: '/manage_list/download_' + $file_type, style: 'display: none;'});
                                } else {
                                    var $element = $('<a download/>').attr({href: '/manage_list/download_novic_' + $file_type, style: 'display: none;'});
                                }

                                $('body').append($element);
                                $element[0].click();
                                $element.remove();
                                return true;
                            } else {
                                // メール送信処理
                                var common = new Common();
                                common.ajaxSubmit('/manage_list/send_csv_mail', 'post', {
                                    password: _this.zip_password,
                                    email_address: this.value.email_address,
                                    get_type: this.value.get_type,
                                    file_type: this.value.file_type
                                }, {
                                    success: function(data, textStatus, xhr) {
                                        swalToast('メールを送信しました', {icon: 'success'});
                                    }
                                });
                                return true;
                            }
                        },
                    });
                };
            })(this));
        };
        /**
         * ダウンロード方法のトグル処理
         */
        ManageList.prototype.toggleCsvDownload = function () {
            if ($('[type="radio"][name="get_type"]:checked').val() === '1' || $('[type="radio"][name="get_type"]:checked').val() === '3') {
                $('[data-show-if-send-mail="false"]').show();
                $('[data-show-if-send-mail="true"]').hide();
            } else {
                $('[data-show-if-send-mail="false"]').hide();
                $('[data-show-if-send-mail="true"]').show();
            }
        };

        /**
         * ONE-Wのコールバック用
         */
        ManageList.prototype.oneWCallback = function () {
            if (!!location.hash) {
                var query = location.hash.replace(/^#/, '');
                var params = new URLSearchParams(query);
                // アクセストークンとstateがフラグメントで指定されている場合はONE-Wからのコールバックとみなし、ゲスト送信処理をコール
                if (params.has('AccessToken') && params.has('state')) {
                    $.ajax({
                        type: 'POST',
                        dataType: 'json',
                        cache: false,
                        timeout: 30000,
                        url: '/manage_list/send_guests',
                        data: {
                            access_token: params.get('AccessToken'),
                            state: params.get('state'),
                        },
                        beforeSend: function () {
                            $.blockUI();
                        }
                    }).done(function (data, textStatus, xhr) {
                        if (data.status === 'OK') {
                            location.href = '/manage_list'; // クエリパラメータを除去してリロード
                        } else {
                            vex.dialog.alert(data.errors[0]);
                        }
                    }).fail(function (xhr, textStatus, errorThrown) {
                        // サーバーエラー
                        vex.dialog.alert('ゲストの送信に失敗しました。もう一度お試しください');
                    }).always(function () {
                        $.unblockUI();
                    });
                }
            }
        };

        return ManageList;
    })();
}).call(this);
