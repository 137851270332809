/**
 * 招待状の内容編集画面
 */
(function () {
    this.ManageContent = (function () {
        function ManageContent() {
            this.init();
        }

        ManageContent.prototype.init = function () {
            /**
             * Vueオブジェクト
             * @type {Vue}
             */
            var app = new Vue({
                el: '[data-view="manage_content"]',
                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * コンポーネント群
                 */
                components: {
                    'recommend-photo-modal': this.recommendPhotoModal(),
                    'select-photo-modal': this.selectPhotoModal(),
                    'credit-description-modal': this.creditDescriptionModal(),
                    'guest-pay-preview-modal': this.guestPayPreviewModal(),
                    'message-sample-modal': this.messageSampleModal(),
                    'fee-message-sample-modal': this.feeMessageSampleModal(),
                },

                /**
                 * データ群
                 */
                data: function () {
                    return {
                        Common: null, // Commonオブジェクト
                        invitation: {}, // 招待状データ
                        form: {}, // ゲスト回答項目
                        sid: '', // 招待状ID
                        wtype: '', // 招待状区分
                        designVersion: '', // デザインテンプレートのバージョン
                        tickets: [], // チケット一覧
                        showGmap: false, // GoogleMapの表示可否
                        showGmap2: false, // GoogleMapの表示可否
                        multiPlace: false, // 二会場の表示可否
                        entryAccept: false, // 受付終了表示
                        showOption: false, // オプション領域の表示可否
                        showPassword: false, // パスワード領域の表示可否
                        selectPosition: null, // 選択中の画像種別（1|2）
                        selectIndex: null, // 選択中の画像リスト内のindex
                        tab: '', // 表示中のタブ（picture|video）
                        imageList1: [], // 画像1のリスト
                        imageList2: [], // 画像2のリスト
                        videoUrl: '',   // 動画のURL
                        videoPlayerUrl: '', // 動画プレイヤーのiframe用URL
                        youtubePcUrlPattern: /^https:\/\/www\.youtube\.com\/watch\?.+$/,   // YouTubeのURLパターン（PC）
                        youtubeMobileUrlPattern: /^https:\/\/m\.youtube\.com\/watch\?.+$/, // YouTubeのURLパターン（モバイル）
                        youtubeShortUrlPattern: /^https:\/\/youtu\.be\/.+$/,               // YouTubeのURLパターン（リンク用URL）
                        youtubeShortVideoUrlPattern: /^https:\/\/(www\.)?youtube\.com\/shorts\/.+$/, // YouTubeのショート動画URLパターン
                        vimeoUrlPattern: /^https:\/\/vimeo\.com\/(\d+)$/,                             // VimeoのURLパターン
                        time1: null, // 挙式の受付時間
                        time2: null, // 挙式の開始時間
                        time3: null, // 披露宴の受付時間
                        time4: null, // 披露宴の開始時間
                        croppingPosition: null, // クロッピング中の画像種別（1|2）
                        croppingIndex: null, // クロッピング中の画像リスト内のindex
                        croppingData: {}, // クロッピング中の情報
                        IMAGE_LIMIT_COUNT_1: 10, // 画像1の最大枚数
                        IMAGE_LIMIT_COUNT_2: 30, // 画像2の最大枚数
                        IMAGE_FILE_LIMIT_SIZE: 15, // アップロード画像の最大は15M
                        recommendPosition: null, // おすすめ画像選択中の画像種別（1|2）
                        recommendIndex: null, // おすすめ画像選択中の画像リスト内のindex
                        errors: [],
                        entryForm: 1, // ゲスト回答の有無
                        tsutsumutoStatus: 0, // つつむと利用フラグ
                        messageAlignment: 2, // メッセージの配置
                        useGuestPayment: null, // ゲスト決済の有無（watchで新旧判定を行うため初期値をnullに設定）
                    }
                },

                /**
                 * 算出プロパティ
                 */
                computed: {
                    // つつむとの端数アラートの表示有無
                    showTsutsumutoFractionAlert: function () {
                        // 会費制を選択していて、決済手段につつむとだけを選択し、会費金額に1000円単位のものが無い場合にアラートを表示
                        if (this.invitation.is_payable !== '1') {
                            return false;
                        }
                        if (this.invitation.payable_credit === '0' && this.invitation.payable_bank === '0' && this.invitation.payable_tsutsumuto === '1' && this.invitation.payable_cash === '0') {
                            var valid = false;
                            for (var i = 0; i < this.tickets.length; i++) {
                                if (Number(this.tickets[i].price) % 1000 === 0) {
                                    valid = true;
                                    break;
                                }
                            }
                            return !valid;
                        }
                        return false;
                    }
                },

                /**
                 * インスタンス生成時に実行される処理
                 */
                created: function () {
                    this.invitation = JSON.parse(document.querySelector('[data-role="invitation_json"]').innerHTML);
                    this.form = JSON.parse(document.querySelector('[data-role="form_json"]').innerHTML);
                    this.sid = document.querySelector('[data-role="sid"]').innerHTML;
                    this.wtype = document.querySelector('[data-role="wtype"]').innerHTML;
                    this.designVersion = document.querySelector('[data-role="design_version"]').innerHTML;
                    this.tickets = JSON.parse(document.querySelector('[data-role="tickets_json"]').innerHTML);
                    this.time1 = document.querySelector('[data-role="time1"]').innerHTML;
                    this.time2 = document.querySelector('[data-role="time2"]').innerHTML;
                    this.time3 = document.querySelector('[data-role="time3"]').innerHTML;
                    this.time4 = document.querySelector('[data-role="time4"]').innerHTML;
                    this.messageAlignment = Number(document.querySelector('[data-role="message_alignment"]').innerHTML);
                    if (this.messageAlignment === 0) {
                        this.messageAlignment = 2;
                    }
                    // メッセージのalignmentを設定
                    this.updateMessageAlignment();

                    if (this.tickets.length === 0) {
                        this.tickets = [{}, {}]; // デフォルトで2件のチケット入力欄を用意
                    }
                    // 招待状にひも付くメディアを振り分ける
                    var medias = JSON.parse(document.querySelector('[data-role="medias_json"]').innerHTML);
                    for (var i = 0; i < medias.length; i++) {
                        var media = medias[i];
                        switch (media.type) {
                            case 'image_1':
                                this.imageList1.push({
                                    url: media.content,
                                });
                                break;
                            case 'image_2':
                                this.imageList2.push({
                                    url: media.content,
                                });
                                break;
                            case 'video':
                                this.videoUrl = media.content;
                                this.reloadVideoPlayer();
                                break;
                        }
                    }

                    // タブ切り替え
                    this.tab = !!this.videoUrl ? 'video' : 'picture';

                    this.useGuestPayment = Number(this.invitation.is_payable) > 0; // ゲスト決済の有無
                },
                /**
                 * 画面描画後に実行される処理
                 */
                mounted: function () {
                    this.Common = new Common();

                    $.ajaxSetup({
                        type: 'POST',
                        dataType: 'json',
                        cache: false,
                        timeout: 300000, // 5分
                    });

                    this.toggleGmap();
                    this.toggleGmap2();
                    this.toggleTsutsumuto();
                    this.bindMultiPlace();
                    this.togglePassword();
                    this.toggleEntryForm();
                },
                /**
                 * ウォッチャー
                 */
                watch: {
                    /**
                     * オンライン支払い区分の変更を検知
                     */
                    useGuestPayment: function (newVal, oldVal) {
                        var _this = this;
                        // オンライン決済をOFF→ONにした場合
                        if ((newVal === true) && (oldVal === false)) {
                            // DBのオンライン決済設定がOFFの場合はデフォルトでご祝儀決済にする
                            if (this.invitation.is_payable === '0') {
                                Vue.set(this.invitation, 'is_payable', '2');
                            }

                            // ゲスト回答項目のメールアドレス入力が必須ではない状態でオンライン決済をOFF→ONにした際にはconfirmを出す
                            if (this.form.mail !== '2') {
                                vex.dialog.confirm({
                                    unsafeMessage: 'ご祝儀・会費の事前クレジット決済を利用する場合は、「ゲスト回答項目のメールアドレス」が自動的に「必須」に変更されます。よろしいですか？',
                                    callback: function (value) {
                                        if (value !== true) {
                                            _this.useGuestPayment = false;
                                        }
                                    }
                                });
                            }
                        }
                    },
                },
                /**
                 * 各種メソッド
                 */
                methods: {
                    /**
                     * フォームのsubmit処理
                     */
                    submit: function (runMicroad) {
                        // ----------
                        // microad
                        // ----------
                        if (runMicroad === true) {
                            try {
                                enhanceTd.WD.start({
                                    "event_date":$('[name="date_value"]').val(),
                                    "venue_name":$('[name="place_value"]').val(),
                                    "venue_address":$('[name="place_address"]').val(),
                                    "dues":$('[name="fee_value"]').val(),
                                });
                            }
                            catch (e) {
                                console.log('microad error');
                            }
                        }

                        var _this = this;
                        var $form = $('#update_form');

                        // VueのDOM更新は非同期のため、DOMの更新が全て終わってから処理を行う
                        Vue.nextTick(function () {
                            if (_this.validate($form)) {
                                _this.Common.ajaxSubmit($form.attr('action'), $form.attr('method'), $form.serialize());
                            }
                        });
                    },

                    /**
                     * バリデーションを行う
                     */
                    validate: function ($form) {
                        var $topImageTypeRadio = $form.find('input[name="top_image_type"]:checked');
                        if ($topImageTypeRadio[0] && $topImageTypeRadio.val() === 'video') {
                            if (validator.isBlank($form.find('input[name="video_url"]').val())) {
                                swalToast('YouTubeのURLを入力してください', {icon: 'warning'});
                                return false;

                                // YouTubeのURLは自動でPC用に置換するため、validateではPC用URLのみを許可する
                            } else if (!this.youtubePcUrlPattern.test(this.videoUrl) && !this.vimeoUrlPattern.test(this.videoUrl)) {
                                swalToast('YouTubeのURLを入力してください', {icon: 'warning'});
                                return false;
                            }
                        } else {
                            if (!$form.find('input[name="image_1_urls[]"]')[0]) {
                                swalToast('トップに表示するイメージ画像を選択してください', {icon: 'warning'});
                                return false;
                            }
                        }

                        // MEMO: T&G つつむと対応
                        var $useTsutsumuto = $form.find('input[name="use_tsutsumuto"]:checked');
                        if ($useTsutsumuto){
                            if ($useTsutsumuto.val() === '1' && $form.find('input[name="tsutsumuto_url"]').val() === '') {
                                swalToast('つつむとを利用する場合は招待状に記載するURLを入力してください', {icon: 'warning'});
                                return false;
                            }
                        }

                        return true;
                    },

                    /**
                     * プレビューを表示する
                     */
                    preview: function () {
                        var $updateForm  = $('#update_form');
                        var $previewForm = $('#form_preview');
                        $previewForm.empty();

                        // 更新用フォームの入力内容をプレビュー用フォームにコピーしフォーム実行
                        $updateForm.find('input[type="text"]:visible, input[type="tel"]:visible,input[type="date"]:visible,input[type="time"]:visible, input[type="radio"]:checked, input[type="checkbox"]:checked, input[type="hidden"], textarea:visible, select:visible').each(function(index, elem) {
                            var $input = $('<input/>').attr({ type: 'hidden', name: $(elem).attr('name') }).val($(elem).val());
                            $previewForm.append($input);
                        });

                        $previewForm[0].submit();
                    },

                    /**
                     * デフォルト画像のURLか否かを返す
                     * @param url
                     */
                    isDefaultImageUrl: function (url) {
                        return /^http[s]?:\/\/[^\/]+\/template\/[^\/]+\/images\/default[^\/]+$/.test(url);
                    },

                    /**
                     * 住所変更時にGoogleMapへ反映する
                     * @param e
                     */
                    bindGmap: function (e) {
                        var address = $(e.currentTarget).val();
                        if (address) {
                            $('[data-role="gmap-iframe"]').attr('src', 'https://www.google.com/maps/embed/v1/place?&key=AIzaSyCKG169aMLM32f9m7cRHooXBs2nup5mzWI&q=' + encodeURIComponent(address));
                        } else {
                            $('[data-role="gmap-iframe"]').attr('src', '');
                        }
                    },
                    bindGmap2: function (e) {
                        var address = $(e.currentTarget).val();
                        if (address) {
                            $('[data-role="gmap-iframe2"]').attr('src', 'https://www.google.com/maps/embed/v1/place?&key=AIzaSyCKG169aMLM32f9m7cRHooXBs2nup5mzWI&q=' + encodeURIComponent(address));
                        } else {
                            $('[data-role="gmap-iframe2"]').attr('src', '');
                        }
                    },

                    /**
                     * GoogleMapの表示切り替え
                     */
                    toggleGmap: function () {
                        this.showGmap = $('input[type="checkbox"][name="place_map"]').is(':checked');
                    },
                    toggleGmap2: function () {
                        this.showGmap2 = $('input[type="checkbox"][name="place_map2"]').is(':checked');
                    },

                    /**
                     * 二会場の設定項目を反映
                     */
                    bindMultiPlace: function(){
                        this.multiPlace = $('input[name="multi_place"]').is(':checked');
                    },

                    /**
                     * 二会場の表示切り替え
                     */
                    toggleMultiPlace: function(){
                        if(this.multiPlace) {
                            this.multiPlace = false;
                            $('input[name="multi_place"]').prop('checked', false);
                            this.showGmap2 = false;
                            $('input[name="place_map2"]').prop('checked', false);
                            $('.place_2 input').val('');
                            $('.place_2 textarea').val('');
                            $('.place_2').addClass('multi_place_hidden');
                        }else{
                            this.multiPlace = true;
                            $('input[name="multi_place"]').prop('checked', true);
                            $('.place_2').removeClass('multi_place_hidden');
                        }
                    },

                    /**
                     * 受付終了表示の切り替え
                     */
                    toggleEntryAccept: function () {
                        this.entryAccept = $('input[type="radio"][name="entry_accept"]:checked').val() === '1';
                        if (!this.entryAccept && !$('[name="accept_finish_message"]').val()) {
                            $('[name="accept_finish_message"]').val("回答の受付を終了いたしました");
                        }
                    },

                    /**
                     * つつむと利用の切り替え
                     */
                    toggleTsutsumuto: function () {
                        var $checked = $('input[type="radio"][name="use_tsutsumuto"]:checked');
                        this.tsutsumutoStatus = Number($checked.val());
                    },

                    /**
                     * チケットの追加
                     */
                    addTicket: function () {
                        this.tickets.push({});
                    },

                    /**
                     * チケットの削除
                     * @param index
                     */
                    deleteTicket: function (index) {
                        this.tickets.splice(index, 1);
                    },

                    /**
                     * 金額フォーマット
                     * @param val
                     * @return {string}
                     */
                    numberFormat: function (val) {
                        return String(val).replace(/(\d)(?=(\d{3})+$)/g , '$1,');
                    },

                    /**
                     * オプション設定表示の切り替え
                     */
                    toggleOption: function () {
                        this.showOption = true;
                        $('.option_arrow').hide();
                    },

                    /**
                     * パスワード表示の切り替え
                     */
                    togglePassword: function () {
                        this.showPassword = $('input[type="radio"][name="password_enable"]:checked').val() === '1';
                    },

                    /**
                     * 画像の選択モーダル表示
                     * @param imagePosition
                     * @param imageIndex
                     */
                    openImageSelectDialog: function (imagePosition, imageIndex) {
                        // 選択中画像の値にセット
                        this.selectPosition = imagePosition;
                        if (imageIndex !== undefined) {
                            this.selectIndex = imageIndex;
                        } else {
                            this.selectIndex = null;
                        }

                        this.$refs.selectPhotoModal.open();
                    },

                    /**
                     * 画像アップロードダイアログの表示
                     */
                    onSelectUpload: function () {
                        this.openImageUploadDialog(this.selectPosition, this.selectIndex);
                    },

                    /**
                     * おすすめモーダルの表示
                     */
                    onSelectRecommend: function () {
                        this.openRecommendPhotoModal(this.selectPosition, this.selectIndex);
                    },

                    /**
                     * 画像アップロード用のダイアログを表示
                     * @param imagePosition
                     * @param imageIndex
                     */
                    openImageUploadDialog: function (imagePosition, imageIndex) {
                        // クロッピング中の値にセット
                        this.croppingPosition = imagePosition;
                        if (imageIndex !== undefined) {
                            this.croppingIndex = imageIndex;
                        } else {
                            this.croppingIndex = null;
                        }

                        // ファイルダイアログ表示
                        var $form = this.$el.querySelector('#upload_for_crop_form');
                        $form.querySelector('input[name="upload_file"]').click();
                    },

                    /**
                     * 画像の削除
                     * @param imagePosition
                     * @param imageIndex
                     */
                    removeImage: function (imagePosition, imageIndex) {
                        this['imageList' + imagePosition].splice(imageIndex, 1);
                    },

                    /**
                     * おすすめ画像選択モーダルを表示する
                     * @param imagePosition
                     * @param imageIndex
                     */
                    openRecommendPhotoModal: function (imagePosition, imageIndex) {
                        // おすすめ画像選択中の値にセット
                        this.recommendPosition = imagePosition;
                        if (imageIndex !== undefined) {
                            this.recommendIndex = imageIndex;
                        } else {
                            this.recommendIndex = null;
                        }

                        this.$refs.recommendPhotoModal.open(this.recommendPosition);
                    },

                    /**
                     * 選択されたおすすめ画像をバインドする
                     * @param url
                     */
                    selectedRecommendPhoto: function (url) {
                        if (this.recommendIndex !== null) {
                            // indexがあればそのURLを差し替え
                            this['imageList' + this.recommendPosition][this.recommendIndex].url = url;
                        } else {
                            // indexが無ければリストに追加
                            this['imageList' + this.recommendPosition].push({
                                url: url,
                            });
                        }
                    },

                    /**
                     * デフォルト画像に戻す
                     * @param imagePosition
                     */
                    defaultImage: function (imagePosition) {
                        var _this = this;
                        vex.dialog.confirm({
                            message: '標準の画像に戻します。よろしいですか？',
                            callback: function (value) {
                                if (value === true) {
                                    $.ajax({
                                        url: '/manage_content/delete_photo',
                                        data: { position: imagePosition },
                                        beforeSend: function () {
                                            $.blockUI();
                                        }
                                    }).done(function (data, textStatus, xhr) {
                                        if (data.status === 'OK') {
                                            // 返却されたデフォルト画像をセット
                                            _this['imageList' + imagePosition][0].url = data.url;
                                        } else {
                                            vex.dialog.alert(data.errors[0]);
                                        }
                                    }).fail(function (xhr, textStatus, errorThrown) {
                                        if (xhr.status === 401) {
                                            // ログインエラーの場合は、セッションタイムアウトとして処理
                                            _this.sessionTimeout();
                                        } else {
                                            // サーバーエラー
                                            vex.dialog.alert('画像の削除に失敗しました。もう一度お試しください');
                                        }
                                    }).always(function () {
                                        $.unblockUI();
                                    });
                                }
                            }
                        });
                    },

                    /**
                     * クロッピング用に画像をアップロードする
                     * @param e
                     */
                    uploadForCrop: function (e) {
                        var _this = this;
                        var input = e.currentTarget;
                        // inputキャンセル時はスルー
                        if (input.value.length === 0) {
                            return;
                        }
                        // バリデーション処理
                        var file = input.files[0];
                        if (!file) {
                            return vex.dialog.alert('対応していないファイルまたは対応していないブラウザです。ファイル形式とお使いのブラウザをご確認ください。');
                        } else {
                            if (file.type.length === 0 || !/^image\/(gif|jpe?g|png)$/.test(file.type)) {
                                return vex.dialog.alert('対応していないファイル形式です。jpeg/gif/pngのいずれかを指定してください。');
                            }
                            if (file.size > (this.IMAGE_FILE_LIMIT_SIZE * 1024 * 1024)) {
                                return vex.dialog.alert('ファイルサイズが大きすぎます。' + this.IMAGE_FILE_LIMIT_SIZE + 'MB以下のファイルを指定してください。');
                            }
                        }
                        // アップロード用プログレスバー表示
                        $.blockUI({
                            message: '<div class="progress_bar_wrapper"><div id="progress_bar" class="progress_bar" style="width: 0%;">0%</div></div>',
                        });

                        // 画像をajaxアップロード
                        $.ajax('/manage_content/upload_for_crop/' + _this.croppingPosition, {
                            data: new FormData(this.$el.querySelector('#upload_for_crop_form')),
                            processData: false,
                            contentType: false,
                            // プログレスバーの表示
                            xhr : function(){
                                var XHR = $.ajaxSettings.xhr();
                                if(XHR.upload){
                                    XHR.upload.addEventListener('progress', function (data){
                                        var progress = Math.ceil(data.loaded / data.total * 100);
                                        $('#progress_bar').css('width', progress + '%').text(progress + '%');
                                        if (Number(progress) === 100) {
                                            $('#progress_bar').text('画像処理中...');
                                        }
                                    }, false);
                                }
                                return XHR;
                            }
                        }).done(function (data, textStatus, xhr) {
                            $.unblockUI();
                            if (data.status === 'OK') {
                                Vue.set(_this.croppingData, 'url', data.url + '?t=' + Date.now());
                                Vue.set(_this.croppingData, 'position', _this.croppingPosition);

                                // クロッピング領域を生成して表示
                                $.blockUI({
                                    message: $('#crop_box'),
                                    css: {
                                        cursor: 'default',
                                        top: '2%',
                                        left: '2%',
                                        width: '96%',
                                        height: '85%', // iPhoneで上下のアクションバーが表示されたときに隠れてしまわないように下部を多めに余白確保
                                    },
                                    overlayCSS: {
                                        backgroundColor: '#000',
                                        opacity: 0.5
                                    },
                                    onBlock: function () {
                                        var ratio = 5 / 3; // 解像度の標準は5:3（v2とv3の2枚目画像）
                                        if (_this.designVersion === 'v3') {
                                            // v3デザインの1枚目の写真の解像度は1:1.618
                                            if (Number(_this.croppingPosition) === 1) {
                                                ratio = 1 / 1.618;
                                            }
                                        }
                                        if (_this.designVersion === 'v4') {
                                            // v4デザインの場合は解像度1:1
                                            ratio = 1;
                                        }

                                        // 画像の領域調整
                                        $('#crop_box img').css("max-height", $(this).height() - 55 + 'px');
                                        // すでにあるcropperを除去し、再度cropper表示
                                        $('#crop_box img').cropper('destroy');
                                        $('#crop_box img').cropper({
                                            aspectRatio: ratio,
                                            rotatable: false,
                                            autoCropArea: 1, // クロップ範囲の初期値は100%で
                                            zoomOnWheel: false, // マウスホイールでのズームOFF
                                            zoomOnTouch: false, // スマホのピンチでのズームOFF
                                        });
                                        // 画像上部OR下部にCSSで補正できない隙間をなくすため、わずかにズームさせる
                                        $('#crop_box img').on('built.cropper', function(){
                                            $('#crop_box .crop_img').cropper('zoom', 0.03);
                                        });
                                    }
                                });
                            } else {
                                vex.dialog.alert(data.errors[0]);
                            }
                        }).fail(function (xhr, textStatus, errorThrown) {
                            $.unblockUI();
                            if (xhr.status === 401) {
                                // ログインエラーの場合は、セッションタイムアウトとして処理
                                _this.sessionTimeout();
                            } else {
                                // サーバーエラー
                                vex.dialog.alert('画像のアップロードに失敗しました。もう一度お試しください');
                            }
                        }).always(function() {
                            input.value = ''; // ファイルの中身はクリア
                        });
                    },

                    /**
                     * クロッピングのキャンセル処理
                     */
                    cropCancel: function () {
                        $.unblockUI();
                        this.croppingData = {};
                    },
                    /**
                     * クロッピングの確定処理
                     */
                    cropSubmit: function () {
                        var _this = this;
                        var data = $('#crop_box .crop_img').cropper('getData');
                        // cropperのデータがまだセットされていないときはsubmitさせない
                        if (!data.width || !data.height) {
                            return;
                        }

                        Vue.set(this.croppingData, 'x', Math.round(data.x));
                        Vue.set(this.croppingData, 'y', Math.round(data.y));
                        Vue.set(this.croppingData, 'w', Math.round(data.width));
                        Vue.set(this.croppingData, 'h', Math.round(data.height));

                        this.Common.ajaxSubmit('/manage_content/submit_crop', 'post', this.croppingData, {
                            // クロッピングされた画像のURLをバインドする
                            success: function(data, textStatus, xhr) {
                                if (_this.croppingIndex !== null) {
                                    // indexがあればそのURLを差し替え
                                    _this['imageList' + _this.croppingPosition][_this.croppingIndex].url = data.url;
                                } else {
                                    // indexが無ければリストに追加
                                    _this['imageList' + _this.croppingPosition].push({
                                        url: data.url,
                                    });
                                }
                            },
                            fail: function(message) {
                                vex.dialog.alert('画像のアップロードに失敗しました。もう一度お試しください');
                            }
                        });
                    },

                    /**
                     * YouTubeのプレーヤーをリロードする
                     */
                    reloadVideoPlayer: function () {
                        var youtubeVideoId = '';
                        var vimeoVideoId   = '';
                        // 動画のURLパターンごとにIDを取得
                        if (this.youtubePcUrlPattern.test(this.videoUrl)) {
                            const params = (new URL(this.videoUrl)).searchParams;
                            youtubeVideoId = params.get('v');
                        } else if (this.youtubeMobileUrlPattern.test(this.videoUrl)) {
                            const params = (new URL(this.videoUrl)).searchParams;
                            youtubeVideoId = params.get('v');
                        } else if (this.youtubeShortUrlPattern.test(this.videoUrl)) {
                            const path = (new URL(this.videoUrl)).pathname;
                            youtubeVideoId = path.replace(/^\/([^\/?&]+).*$/, '$1');
                        } else if (this.youtubeShortVideoUrlPattern.test(this.videoUrl)) {
                            let path = (new URL(this.videoUrl)).pathname;
                            path = path.replace(/^\/shorts/, '');
                            youtubeVideoId = path.replace(/^\/([^\/?&]+).*$/, '$1');
                        } else if (this.vimeoUrlPattern.test(this.videoUrl)) {
                            vimeoVideoId = this.videoUrl.replace(this.vimeoUrlPattern, '$1');
                        }

                        if (!youtubeVideoId && !vimeoVideoId) {
                            this.videoPlayerUrl = '';
                            vex.dialog.alert('YouTubeの動画URLを入力してください');
                            return;
                        }
                        if (!!youtubeVideoId) {
                            // YouTubeのURLは自動でPC用に変換する（秒数指定等の他のパラメータは無視する）
                            this.videoUrl = 'https://www.youtube.com/watch?v=' + youtubeVideoId;
                            var origin = location.protocol + '//' + location.hostname;
                            this.videoPlayerUrl = 'https://www.youtube.com/embed/' + youtubeVideoId + '?origin=' + origin;
                        } else {
                            // VimeoのプレイヤーURL生成
                            this.videoPlayerUrl = 'https://player.vimeo.com/video/' + vimeoVideoId;
                        }
                    },

                    /**
                     * セッションタイムアウト処理
                     */
                    sessionTimeout: function () {
                        vex.dialog.alert({
                            message: 'セッションが切れました。再度ログインしてください。',
                            callback: function () {
                                location.reload(true);
                            }
                        });
                    },

                    /**
                     * ゲスト回答の表示切り替え
                     */
                    toggleEntryForm: function () {
                        if($('input[type="checkbox"][name="is_entry_enable"]').length) {
                            this.entryForm = $('input[type="checkbox"][name="is_entry_enable"]').is(':checked');
                        }
                        //ご祝儀・会費の事前クレジット決済のラジオボタンを制限
                        if(!this.entryForm){
                            this.useGuestPayment = false;
                        }
                    },

                    /**
                     * ご祝儀・会費の事前クレジット決済説明モーダル表示
                     */
                    openCreditDescriptionModal: function () {
                        this.$refs.creditDescriptionModal.open();
                    },
                    /**
                     * ご祝儀・会費のオンライン支払い画面のイメージモーダル表示
                     */
                    openGuestPayPreviewModal: function () {
                        this.$refs.guestPayPreviewModal.open();
                    },
                    /**
                     * 例文選択モーダル表示
                     */
                    openMessageSampleModal: function () {
                        this.$refs.messageSampleModal.open();
                    },
                    /**
                     * メッセージのtext-alignを更新
                     */
                    updateMessageAlignment: function() {
                        var textarea = document.querySelector('textarea[name="message"]');
                        // 既存のalignmentクラスを削除
                        textarea.classList.remove('text-left', 'text-center', 'text-right');

                        // 新しいalignmentクラスを追加
                        switch(Number(this.messageAlignment)) {
                            case 1:
                                textarea.classList.add('text-left');
                                break;
                            case 2:
                                textarea.classList.add('text-center');
                                break;
                            case 3:
                                textarea.classList.add('text-right');
                                break;
                        }
                    },

                    // messageAlignmentが変更されたときに呼び出されるメソッド
                    onMessageAlignmentChange: function() {
                        this.updateMessageAlignment();
                    },

                    /**
                     * 会費メッセージの例文選択モーダル表示
                     */
                    openFeeMessageSampleModal: function () {
                        this.$refs.feeMessageSampleModal.open();
                    },
                }
            });
        };

        /**
         * 画像選択モーダルのVueコンポーネント
         */
        ManageContent.prototype.selectPhotoModal = function () {
            return {
                /**
                 * テンプレート
                 */
                template: '#manage-content-select-photo-modal-template',

                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        modal: null, // remodalオブジェクト
                    }
                },
                /**
                 * 画面描画後に実行される処理
                 */
                mounted: function () {
                    // モーダルの初期化
                    this.modal = $(this.$el).remodal({
                        hashTracking: false,
                        modifier: 'l-remodal remodal-base select-photo-modal', // classをオーバーライド
                    });
                },

                /**
                 * 各種メソッド
                 */
                methods: {
                    /**
                     * モーダルを開く
                     */
                    open: function () {
                        this.modal.open();
                    },
                    /**
                     * アップロードの選択処理
                     */
                    emitUpload: function () {
                        // 親に送出してモーダルを閉じる
                        this.$emit('on-select-upload');
                        this.modal.close();
                    },
                    /**
                     * おすすめ画像の選択処理
                     */
                    emitRecommend: function () {
                        // 親に送出してモーダルを閉じる
                        this.$emit('on-select-recommend');
                        this.modal.close();
                    },
                }
            };
        };

        /**
         * おすすめ画像選択モーダルのVueコンポーネント
         */
        ManageContent.prototype.recommendPhotoModal = function () {
            return {
                /**
                 * テンプレート
                 */
                template: '#manage-content-recommend-photo-modal-template',

                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        Common: null,
                        modal: null, // remodalオブジェクト
                        position: null,
                        searchResults: [], // 取得結果
                        errors: [],
                    }
                },
                /**
                 * 画面描画後に実行される処理
                 */
                mounted: function () {
                    this.Common = new Common();

                    // モーダルの初期化
                    this.modal = $(this.$el).remodal({
                        hashTracking: false,
                        modifier: 'l-remodal remodal-full-height recommend-photo-modal', // classをオーバーライド
                    });
                },

                /**
                 * 各種メソッド
                 */
                methods: {
                    /**
                     * モーダルを開く
                     */
                    open: function (position) {
                        this.position = position;
                        this.modal.open();
                        this.fetchPhotos();
                    },
                    /**
                     * おすすめ画像を取得して表示する
                     */
                    fetchPhotos: function () {
                        var _this = this;
                        this.searchResults = [];
                        this.Common.ajaxSubmit('/manage_content/get_recommend_photo_pathes', 'post', { position: this.position }, {
                            // おすすめ画像をバインドする
                            success: function(data, textStatus, xhr) {
                                _this.searchResults = data.result;
                            },
                            fail: function(message) {
                                vex.dialog.alert('おすすめ画像の取得に失敗しました。');
                            }
                        });
                    },
                    /**
                     * おすすめ画像の選択処理
                     * @param url
                     */
                    selectPhoto: function (url) {
                        // 親に送出してモーダルを閉じる
                        this.$emit('on-select', url);
                        this.modal.close();
                    },
                }
            };
        };

        /**
         * ご祝儀・会費の事前クレジット決済説明モーダルのVueコンポーネント
         */
        ManageContent.prototype.creditDescriptionModal = function () {
            return {
                /**
                 * テンプレート
                 */
                template: '#manage-content-credit-description-modal-template',

                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        modal: null, // remodalオブジェクト
                    }
                },
                /**
                 * 画面描画後に実行される処理
                 */
                mounted: function () {
                    // モーダルの初期化
                    this.modal = $(this.$el).remodal({
                        hashTracking: false,
                        modifier: 'l-remodal remodal-base credit-description-modal', // classをオーバーライド
                    });
                },

                /**
                 * 各種メソッド
                 */
                methods: {
                    /**
                     * モーダルを開く
                     */
                    open: function () {
                        this.modal.open();
                    },
                }
            };
        };

        /**
         * ご祝儀・会費のオンライン支払い画面のイメージモーダルのVueコンポーネント
         */
        ManageContent.prototype.guestPayPreviewModal = function () {
            return {
                /**
                 * テンプレート
                 */
                template: '#manage-content-guest-pay-preview-modal-template',

                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        modal: null, // remodalオブジェクト
                    }
                },
                /**
                 * 画面描画後に実行される処理
                 */
                mounted: function () {
                    // モーダルの初期化
                    this.modal = $(this.$el).remodal({
                        hashTracking: false,
                        modifier: 'l-remodal remodal-base guest-pay-preview-modal', // classをオーバーライド
                    });
                },

                /**
                 * 各種メソッド
                 */
                methods: {
                    /**
                     * モーダルを開く
                     */
                    open: function () {
                        this.modal.open();
                    },
                }
            };
        };

        /**
         * 例文選択モーダルのVueコンポーネント
         */
        ManageContent.prototype.messageSampleModal = function () {
            return {
                /**
                 * テンプレート
                 */
                template: '#manage-content-message-sample-modal-template',

                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        sampleMessages: [], //例文一覧
                        modal: null, // remodalオブジェクト
                    }
                },
                /**
                 * インスタンス生成時に実行される処理
                 */
                created: function () {
                    this.sampleMessages = JSON.parse(document.querySelector('[data-role="sample_messages_json"]').innerHTML);
                },
                    /**
                 * 画面描画後に実行される処理
                 */
                mounted: function () {
                    // モーダルの初期化
                    this.modal = $(this.$el).remodal({
                        hashTracking: false,
                        modifier: 'l-remodal remodal-base message-sample-modal', // classをオーバーライド
                    });
                },

                /**
                 * 各種メソッド
                 */
                methods: {
                    /**
                     * モーダルを開く
                     */
                    open: function () {
                        this.modal.open();
                    },
                    selectMessageSample: function(index) {
                        var that = this;
                        var currentMessage = $('textarea[name="message"]').val();

                        if (currentMessage.trim() === "") {
                            $('textarea[name="message"]').val(that.sampleMessages[index].content);
                            that.modal.close();
                        } else {
                            // 現在のメッセージが空白でない場合、確認ダイアログを表示
                            vex.dialog.confirm({
                                message: '既に設定されている案内文を上書きします。よろしいですか？',
                                callback: function (value) {
                                    if (value === true) {
                                        $('textarea[name="message"]').val(that.sampleMessages[index].content);
                                        that.modal.close();
                                    }
                                }
                            });
                        }
                    },
                }
            };
        };

        /**
         * 会費メッセージの例文選択モーダルのVueコンポーネント
         */
        ManageContent.prototype.feeMessageSampleModal = function () {
            return {
                /**
                 * テンプレート
                 */
                template: '#manage-content-fee-message-sample-modal-template',

                /**
                 * ミックスインオブジェクト
                 */
                mixins: [
                    CommonMixin,
                ],
                /**
                 * データ群
                 */
                data: function () {
                    return {
                        // 例文一覧
                        sampleMessages: [
                            {
                                label: "ご祝儀制の場合",
                                content: "ご列席の皆様のご負担や当日の混雑を避けるべく\nご祝儀の事前受付を可能といたしました\n\n受付でのご祝儀の受け渡しが不要となりますので\nよろしければ事前受付をご利用ください"
                            },
                            {
                                label: "会費制の場合",
                                content: "ご列席の皆様のご負担や当日の混雑を避けるべく\n会費の事前受付を可能といたしました\n\n受付での会費の受け渡しが不要となりますので\nよろしければ事前受付をご利用ください"
                            }
                        ],
                        modal: null, // remodalオブジェクト
                    }
                },
                /**
                 * 画面描画後に実行される処理
                 */
                mounted: function () {
                    // モーダルの初期化
                    this.modal = $(this.$el).remodal({
                        hashTracking: false,
                        modifier: 'l-remodal remodal-base message-sample-modal', // classをオーバーライド
                    });
                },

                /**
                 * 各種メソッド
                 */
                methods: {
                    /**
                     * モーダルを開く
                     */
                    open: function () {
                        this.modal.open();
                    },
                    selectMessageSample: function(index) {
                        var that = this;
                        var currentMessage = $('textarea[name="fee_remarks"]').val();

                        if (currentMessage.trim() === "") {
                            $('textarea[name="fee_remarks"]').val(that.sampleMessages[index].content);
                            that.modal.close();
                        } else {
                            // 現在のメッセージが空白でない場合、確認ダイアログを表示
                            vex.dialog.confirm({
                                message: '既に設定されている内容を上書きします。よろしいですか？',
                                callback: function (value) {
                                    if (value === true) {
                                        $('textarea[name="fee_remarks"]').val(that.sampleMessages[index].content);
                                        that.modal.close();
                                    }
                                }
                            });
                        }
                    },
                }
            };
        };

        return ManageContent;
    })();
}).call(this);
